import { Center, Spinner } from '@chakra-ui/react';

const SimpleSpinner = () => {
  return (
    <Center>
      <Spinner my='5' color='primary.500' />
    </Center>
  );
};

export default SimpleSpinner;
